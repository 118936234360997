:root{
  --green:#27ae60;
  --black:#192a56;
  --light-color:#666;
  --box-shadow:0 .5rem 1.5rem rgba(0,0,0,.1);
}

html {
  font-size: 16px;
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  overflow-x: hidden !important;
  line-height: 1.5;
  letter-spacing: 0.3px;
  font-family: "LEMON MILK";
}
section{
  padding:2rem 9%;
}

section:nth-child(even){
  background:#eee;
}

.slider {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
  width: 100%;
}

.slider--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.slider--feature {
  text-align: center;
}

.feature--title {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
}

.feature--text {
  font-size: 2rem;
  color: #fff;
  margin: 1rem 0;
}

.slider__btn-right,
.slider__btn-left {
  background: transparent;
  border: none;
  outline: none;
  font-size: 4rem;
  color: #eee;
  padding: 0 1rem;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.feature__btn {
  background: #fff;
  text-transform: uppercase;
  border: none;
  color: #444;
  border: 1px solid #444;
  outline: none;
  font-weight: 700;
  padding: 0.8rem 2rem;
  cursor: pointer;
}

.slider__btn-left:hover,
.slider__btn-right:hover {
  transform: scale(0.95);
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
  background: "#f5f5f8";
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn 0.5s;
}

/* Skills */
[class^="skill-"] {
  position: relative;
}

.skill-image {
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.skill-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 1s;
}

.skill-right .skill-image {
  right: 0;
}

.skill-right .skill-description {
  padding: 36px 55% 36px 0;
  text-align: right;
  z-index: 0;
}

.skill-left .skill-description {
  padding: 36px 0 36px 55%;
  text-align: left;
  z-index: 0;
}

.skill-image img:hover {
  transform: scale(0.8);
}

.read-more-link {
  color: cornflowerblue;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.extra-content {
  font-weight: 500;
}